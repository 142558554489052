import React, { useContext, useEffect, useState } from "react";
import backgroundImage from "./themeimg/dtLite/page_bg_image.jpg"
import headerImg from "./themeimg/dtLite/dream_travel_logo.png"
import banner from "./themeimg/dtLite/subscription_banner3-min.jpg"
import { baseUrl, data, headers } from '../config/config';
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import { SendGuiDataEvents } from "../CommonScript";
import LoaderComponent from './Loader';
import { Context } from "../Context/Context";

const Subscription = ()=>{

    const msisdn = localStorage.getItem("msisdn") ? localStorage.getItem("msisdn") : localStorage.getItem("msisdn2")
    const navigate = useNavigate()
    const lang = localStorage.getItem("lang") || "fr"
    const [scriptLoaded,setScriptLoaded] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [loading,setLoading] = useState(false)
    const [tid,setTid] = useState("")
    const location = useLocation()
    const camp = localStorage.getItem("camp")
    const rcid = localStorage.getItem("rcid")

    const {pageLoader , handlePageLoader, changeErrorState,setPageLoader} = useContext(Context)

    const Callback = () => {
      let guiEvents={};
    guiEvents['page']="subscription";
    guiEvents["event"] = "ad_campaign_click";
    guiEvents["opco"] = "mtn_cmr_dt";
    guiEvents["camp"] = camp;
    guiEvents["rcid"] = rcid;
    guiEvents["ua"] = "mobile agent"
    SendGuiDataEvents(guiEvents);
        console.log("callback called")
        const url = "https://camp-reports.bngrenew.com:5455/digitalservice/api/sendcallback"
        const headers={
            // ...config.get('headers')
            "Content-Type":"application/json"
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({ 'camp': camp, 'rcid': rcid, "opco": "mtn_cmr_dt", "ua": "mobile agent", "msisdn": msisdn}),
            headers: headers
        })
            .then(res => {
              console.log("response",res)
                if(res.ok === true){
                    localStorage.removeItem("camp")
                    localStorage.removeItem("rcid")
                }
                if (!res.ok) { throw res }
                return res.json()
            })
            .then((result) => {
               if(result === "ok"){
                // checksub()
                localStorage.removeItem("camp")
                localStorage.removeItem("rcId")
               }
            },
                (error) => {
                    console.log('error:::', error)
                })
    }
    

    const ProtectedScript =() =>{
      handlePageLoader(true);
  
      if (document.getElementById("dcbProtect")) {
        console.log("DCBProtect script is already loaded");
        setScriptLoaded(true);
        handlePageLoader(false);
        return;
      }
  
      axios({
        method: 'post',
        url: baseUrl + "dcbScript",
        headers: headers,
      }).then((resp) => {
        console.log('response', resp.data);
        if(resp.data.status === "success"){
          // hitTransCheck(resp.data.tid)
          setTid(resp.data.tid)
          setIsButtonDisabled(false);
        }
  
        const scriptContent = resp.data.script;
        if (scriptContent) {
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.text = scriptContent;
          script.id = "dcbProtect";
          document.body.appendChild(script);
          setScriptLoaded(true);
          setIsButtonDisabled(false);
  
          // Directly check for cta_button without relying on DOMContentLoaded
          const ctaButton = document.getElementById('cta_button');
          document.getElementById('cta_button').disabled = false;
          if (ctaButton) {
            console.log("cta_button is ready");
            const ev = new Event('DCBProtectRun');
            document.dispatchEvent(ev);
          } else {
            console.error("cta_button not found in DOM.");
          }
        } else {
          console.error("DCBProtect script content is empty");
        }
  
        let transactionID = resp.data.transaction_identify;
        localStorage.setItem("transactionID", transactionID);
  
        if (resp.data.status !== "success") {
          navigate('/thanks', {
            state: { message: resp?.data?.message }
          });
        }
  
      }).catch((error) => {
        console.log("error -----> ", error);
      }).finally(() => {
        handlePageLoader(false);
      });
    }

    
  

      const hitTransCheck=()=>{
        handlePageLoader(true)
          axios({
             method:"post",
             url: baseUrl + 'transCheck',
             data: JSON.stringify({ tid: tid,msisdn:msisdn }),
             headers:headers
          }).then((resp)=>{
            if(resp.data.status === "success"){
                Subscribe(msisdn)
            }
            else{
                navigate('/thanks', {
                    state: { message: resp.data.message}
                  });
            }
            handlePageLoader(false)
          }
        )
      }

      const hitTransaction=(transId,subId)=>{
        setLoading(true)
          axios({
             method:"post",
             url: baseUrl + 'transaction',
             data: JSON.stringify({ transactionId: transId, subServiceId: subId, tid: tid }),
             headers:headers
          }).then((resp)=>{
            if(resp.data.status === "success"){
                navigate("/home")
            }
            else{
                navigate('/thanks', {
                    state: { message: resp.data.message}
                  });
            }
            setLoading(false)
          }
        )
      }

    const Subscribe = async (msisdn) => {
        setLoading(true)
        try {
          const resp = await axios({
            method: 'post',
            url: baseUrl + 'subscribe',
            data: { msisdn: msisdn, Packtype:"daily" },
            headers: headers,
          });
      
          const currentStatus = resp.data.subStatus;
          if (resp.data.status !== '' && resp.data.status !== undefined) {
            if (resp.data.status === 'failed') {
                navigate('/thanks', {
                    state: { message: resp?.data?.message,error:"technical",title:resp?.data?.title }
                  });
            }
            else if (resp.data.status === 'success') {
              if (currentStatus === 'active') {
                  let guiEvents = {
                      page: "subscription",
                      status: currentStatus,
                      event: "subscribed_user"
                  };
                  await SendGuiDataEvents(guiEvents);
                  const Subscribe = async (msisdn) => {
        setLoading(true)
        try {
          const resp = await axios({
            method: 'post',
            url: baseUrl + 'subscribe',
            data: { msisdn: msisdn, Packtype:"daily" },
            headers: headers,
          });
      
          const currentStatus = resp.data.subStatus;
          if (resp.data.status !== '' && resp.data.status !== undefined) {
            if (resp.data.status === 'failed') {
                navigate('/thanks', {
                    state: { message: resp?.data?.message,error:"technical",title:resp?.data?.title }
                  });
            }
            else if (resp.data.status === 'success') {
              if (currentStatus === 'active') {
                  let guiEvents = {
                      page: "subscription",
                      status: currentStatus,
                      event: "subscribed_user"
                  };
                  await SendGuiDataEvents(guiEvents);
                  setLoading(false)
                  await checkSubHandler(); 

                  if (camp && rcid) {
                      Callback();
                  } 
              } else if (currentStatus === 'pending') { 
                console.log("Loader started:", loading); 
                
                setTimeout(() => {
                    setLoading(false); 
                    
                    
                    checkSubHandler();  
                }, 5000);

                 
                  if (camp && rcid) {
                      Callback();
                  } 
              } else {
                 
                  navigate('/thanks', {
                      state: { message: resp?.data?.message }
                  });
              }
          }
           
          }
          
        } catch (error) {
          console.error('An error occurred while checksub:', error);
        }
        
        
      }

                  await checkSubHandler(); 

                  if (camp && rcid) {
                      Callback();
                  } 
              } else if (currentStatus === 'pending') {
                setLoading(true); 
                console.log("Loader started:", loading); 
                
                setTimeout(() => {
                    setLoading(false); 
                    
                    
                    checkSubHandler();  
                }, 5000);

                 
                  if (camp && rcid) {
                      Callback();
                  } 
              } else {
                 
                  navigate('/thanks', {
                      state: { message: resp?.data?.message }
                  });
              }
          }
           
          }
          
        } catch (error) {
          console.error('An error occurred while checksub:', error);
        }
        
        
      }


      
      const checkSubHandler = async () => {
        setLoading(true)
        try {
          const resp = await axios({
            method: 'post',
            url: baseUrl + 'checkSub',
            data: {msisdn:msisdn},
            headers: headers,
          });
      
          const currentStatus = resp.data.subStatus;
          console.log("response",resp.data)
          if (resp.data.status !== '' && resp.data.status !== undefined) {
            if (resp.data.status === 'failed') {
              navigate('/thanks', {
                state: { message: resp?.data?.message}
              });
            } else if (resp.data.status === 'success') {
               if (resp.data.subStatus === 'active') {
                hitTransaction(resp.data.transactionId, resp.data.subServiceId);
              } 
              else{
                navigate('/thanks', {
                    state: { message: resp?.data?.message}
                  });
            }
            }
            else{
                navigate('/thanks', {
                    state: { message: resp?.data?.message}
                  });
            }
            setLoading(false)
          }
      
          
          localStorage.setItem("subStatus", resp.data.subStatus);
        } catch (error) {
          console.error('An error occurred while checksub:', error);
        }
      }

      useEffect(()=>{
        ProtectedScript()
      },[])

      
    return(
        <div className="max-w-[500px] mx-auto h-[100vh] bg-[#FFCC00]" 
             >
                {(pageLoader || loading) && <LoaderComponent />}
            <div><img className="w-[65%] pt-2 mx-auto" src={headerImg} alt="header" /></div>
            <div className="theme-bg bg-cover bg-no-repeat">
                <div className="w-full mt-6 bg-[#FFCC00] relative">
                    <img src={banner} className="h-[40vh] w-[100%] mx-auto rounded-xl"></img>
                    <div className="absolute bottom-24 sm:bottom-20 w-full text-center text-white text-2xl">
                      {lang === "fr" ? "Bienvenue chez Voyage de Rêve" : "Welcome to Dream Travel"}
                    </div>
                    <div className="absolute bottom-8 sm:bottom-10 w-full text-center text-white text-3xl font-extrabold">
                    {lang === "fr" ? "Méga Concours Ile Maurice" : "Mega Contest Mauritius"}
                    </div>
                </div>
                <div className="w-[80%]  text-black text-xl ml-8 mt-10 tracking-wide font-extrabold">
                  {lang === "fr" ? "Veuillez confirmer pour vous abonner" : "Please confirm to subscribe"}
                </div>
                <div className="w-[80%]  text-black ml-8  mt-6 tracking-wide font-extrabold">
                {lang === "fr" ? "Pack journalier - 100 frs" : "Daily Pack - 100 Frs"} 
                </div>
                <div className="w-[80%]  text-black ml-8 mt-2 tracking-wide text-sm">
                {lang === "fr" ? "*Des micro-frais peuvent s'appliquer" : "*Micro Changes may apply"}
                </div>
                <div className="mt-10 flex">
                    <button className={ ` ${isButtonDisabled ? "opacity-15" : ""} border-1 text-[#FFCC00] bg-black font-thin rounded-2xl w-[80%] justify-center mx-auto p-2`} id="cta_button" onClick={hitTransCheck} 
                    // onClick={() => Subscribe(msisdn)}
             disabled={isButtonDisabled}>{lang === "fr" ? "S'abonner" : "Subscribe"}</button>
                </div>
            </div>
        </div>
    )
}

export default Subscription
